import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import posterRo from "../../images/rollovers/graphic-design/poster-ro.png"
import "../layout.css"

import img1 from '../../images/graphic-design/posters/1.jpg'
import img2 from '../../images/graphic-design/posters/2.jpg'
import img3 from '../../images/graphic-design/posters/3.jpg'
import img4 from '../../images/graphic-design/posters/4.jpg'
import img5 from '../../images/graphic-design/posters/5.jpg'
import img6 from '../../images/graphic-design/posters/6.jpg'
import img7 from '../../images/graphic-design/posters/7.jpg'
import img8 from '../../images/graphic-design/posters/8.jpg'
import img9 from '../../images/graphic-design/posters/9.jpg'
import img10 from '../../images/graphic-design/posters/10.jpg'
import img11 from '../../images/graphic-design/posters/11.jpg'
import img12 from '../../images/graphic-design/posters/12.jpg'
import img13 from '../../images/graphic-design/posters/13.jpg'
import img14 from '../../images/graphic-design/posters/14.jpg'

export default function Poster() {
    let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14]
    return (
        <GalleryBackground title="Poster" imgSrc={posterRo} >
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}